/* eslint no-shadow: ["error", { "allow": ["Vue"] }] */
/* eslint-disable no-param-reassign */
import Vue from 'vue';
import Keycloak from 'keycloak-js';
import infoApi from '@/backend-info-api';

async function createKeycloak() {
  const response = await infoApi.getAppInfo();
  const initOptions = {
    url: response.data.keycloakBaseUrl,
    realm: response.data.keycloakRealm,
    clientId: 'vue-client',
    onLoad: 'login-required',
    pkceMethod: 'S256',
    responseMode: 'query',
  };
  return new Keycloak(initOptions);
}

export const keycloakPromise = createKeycloak();

const Plugin = {
  install() {
    keycloakPromise.then((keycloak) => {
      Vue.$keycloak = keycloak;
      Object.defineProperties(Vue.prototype, {
        $keycloak: {
          get() {
            return keycloak;
          },
        },
      });
    });
  },
};

Vue.use(Plugin);

export default Plugin;
