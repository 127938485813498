export default {
  data() {
    return {
      platform: '',
    };
  },
  beforeMount() {
    const isTouchDevice = 'ontouchstart' in window;
    if (isTouchDevice) {
      if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
        this.platform = 'ios';
      } else {
        this.platform = 'touchDevice'; // Default touchDevice if not iOS
      }
    } else {
      this.platform = 'desktop';
    }
  },
};
