export default {
  data() {
    return {
      vertreterBildVorhanden: true,
    };
  },
  methods: {
    getDefaultMandantenBild() {
      this.vertreterBildVorhanden = false;
      const images = require.context('./assets/', false, /\.(png|svg)/);
      if (this.mandant === 1) {
        return images('./vgh-logo.png');
      }
      if (this.mandant === 3) {
        return images('./Meine_OESA_Logo.svg');
      }
      if (this.mandant === 5) {
        return images('./OEVO-Logo.png');
      }
      return '';
    },
    getQuadratischesMandantenBild() {
      const images = require.context('./assets/', false, /\.jpg$/);
      if (this.mandant === 1) {
        return images('./VGH_Nachricht_pic.jpg');
      }
      if (this.mandant === 3) {
        return images('./OESA_Nachricht_pic.jpg');
      }
      if (this.mandant === 5) {
        return images('./OEVO_Nachricht_pic.jpg');
      }
      return '';
    },
  },
};
