<template>
  <div>
    <div>
      <div v-if="!loading" class="container main-content">
        <slot name="benachrichtigung"></slot>
        <div class="row g-3">
          <div class="col-xl-8 col-lg-8">
            <div id="produkt">
              <h1 class="ueberschrift">{{vertrag.produktname}}</h1>
              <div class="copy_16 text" v-if="!isKFZ(vsnr)">{{ vertrag.spezifischesMerkmal }}</div>
            </div>
            <div class="row row-cols-xl-2">
              <div :class="{'col-xl-6 col-lg-6': vschein || beitragsrechnung,
                            'col-xl-12 col-lg-12': !vschein && !beitragsrechnung}"
                   id="vertragsdaten">
                <h2 class="ueberschrift">Vertragsdaten</h2>
                <vertragsdetail v-if="vertrag.vsnr">
                  <template v-slot:unter-ueberschrift>Vertragsnummer</template>
                  <template v-slot:inhalt>{{ vertrag.vsnr }}</template>
                </vertragsdetail>
                <vertragsdetail v-if="vertrag.versicherungsnehmer">
                  <template v-slot:unter-ueberschrift>Versicherungsnehmer</template>
                  <template v-slot:inhalt>
                  <span v-if="vertrag.versicherungsnehmer.titel">
                    {{ vertrag.versicherungsnehmer.titel + ' '}}
                  </span>
                    {{ vertrag.versicherungsnehmer.vorname + ' ' }}
                    <span v-if="vertrag.versicherungsnehmer.namensvorsatz">
                    {{ vertrag.versicherungsnehmer.namensvorsatz + ' '}}
                  </span>
                    {{ vertrag.versicherungsnehmer.name }}
                  </template>
                </vertragsdetail>
                <vertragsdetail v-if="vertrag.vertragsbeginn" id="laufzeitbeginn-hinweis">
                  <template v-slot:unter-ueberschrift>Vertragsbeginn</template>
                  <template v-slot:inhalt>{{ vertrag.vertragsbeginn }}</template>
                </vertragsdetail>
                <vertragsdetail v-if="vertrag.vertragsende" id="laufzeitende-hinweis">
                  <template v-slot:unter-ueberschrift>Laufzeitende
                    <div @click.self="toggleHinweis"
                         class="hilfe-icon"
                         :class="{'vgh-blau-bg': mandant === 1,
                        'oesa-gruen-bg': mandant === 3,
                        'oevo-blau-bg': mandant === 5}">?
                      <div v-if="showHinweis" class="hilfe-text">
                        <div class="row">
                          <div class="col-12">
                            <div id="close-btn" @click="schliesseHinweis">
                              <em class="fa-solid fa-xmark"></em>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                          <span class="hinweis-text-span">
                            Ohne fristgerechte Kündigung kann sich
                            die Laufzeit automatisch verlängern.
                          </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-slot:inhalt>{{ vertrag.vertragsende }}</template>
                </vertragsdetail>
                <vertragsdetail v-if="vertrag.bedingungswerk">
                  <template v-slot:unter-ueberschrift>Bedingungen</template>
                  <template v-slot:inhalt>
                    <a class="copy_16 text text-link">
                      {{ vertrag.bedingungswerk }}
                    </a>
                  </template>
                </vertragsdetail>
                <div v-if="!isSparkasseOderMakler && darfSchadenMelden" id="schaden-melden-btn">
                  <pfeil-button :class="{'vgh-blau-color': mandant === 1,
                                'oesa-gruen-color': mandant === 3,
                                'oevo-blau-color': mandant === 5}"
                                button-text="Schaden melden"
                                path="schaden-melden"
                                :param="vertrag.vsnr + '/'
                               + vertrag.laufendenr + '/'
                               + vertrag.produktkey"
                  :disabled="isKrankenversicherung(vsnr)">
                  </pfeil-button><div @click.self="toggleHinweis"
                     v-if="isKrankenversicherung(vsnr)"
                     class="hilfe-icon"
                     :class="{'vgh-blau-bg': mandant === 1,
                      'oesa-gruen-bg': mandant === 3,
                      'oevo-blau-bg': mandant === 5}">?
                  <div v-if="showHinweis" class="hilfe-text">
                    <div class="row">
                      <div class="col-12">
                        <div id="close-btn" @click="schliesseHinweis">
                          <em class="fa-solid fa-xmark"></em>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                      <span class="hinweis-text-span">
                        <span class="copy_16">
                          Ihre Schadenmeldungen, Rechnungen, Rezepte sowie Heil- und Kostenpläne zur
                          Krankenversicherung können Sie ganz einfach über die App
                          <span v-if="mandant === 5 ||
                          (platform === 'desktop' && mandant !== 5)">
                          {{ setMandantAppName() }}</span>
                          <a v-else-if="mandant === 1 || mandant === 3"
                          :href="getAppStoreKranken()" target="_blank">
                          {{ setMandantAppName() }}
                          </a>
                          einreichen.
                        </span>
                      </span>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
                <div v-if="!isSparkasseOderMakler"
                     style="margin-bottom: 20px">
                  <pfeil-button :class="{'vgh-blau-color': mandant === 1,
                                'oesa-gruen-color': mandant === 3,
                                'oevo-blau-color': mandant === 5}"
                                button-text="Dokument einreichen"
                                path="dokument-einreichen"
                                :param="vertrag.vsnr + '/'
                               + vertrag.laufendenr + '/'
                               + vertrag.produktkey"
                  >
                  </pfeil-button>
                </div>
                <div v-if="!isSparkasseOderMakler && false">
                  <pfeil-button :class="{'vgh-blau-color': mandant === 1,
                                'oesa-gruen-color': mandant === 3,
                                'oevo-blau-color': mandant === 5}"
                                button-text="Dokumente anfordern"
                                path="dokumente-anfordern"
                                :param="vertrag.vsnr + '/'
                               + vertrag.laufendenr + '/'
                               + vertrag.produktkey"
                  >
                  </pfeil-button>
                </div>
              </div>
              <div class="col-xl-6 info-bereich"
                   v-if="vschein || beitragsrechnung"
                   id="vertragsdokumente">
                <div class="collapsible">
                  <h2 class="ueberschrift">
                    <button class="btn btn-collapse" type="button" :disabled="windowWidth > 1200"
                            v-b-toggle.vertragsdokumentcollapse>
                      Vertragsdokumente
                      <span class="icon"
                            :class="{'vgh-blau-color': mandant === 1,
                                'oesa-gruen-color': mandant === 3,
                                'oevo-blau-color': mandant === 5}">
                        <em class="fa-solid fa-plus"></em>
                        <em class="fa-solid fa-minus"></em>
                      </span>
                    </button>
                  </h2>
                  <b-collapse id="vertragsdokumentcollapse" :visible="windowWidth > 1200">
                    <vertragsdetail>
                      <template v-slot:inhalt>
                        <div v-if="vschein">
                          <div :class="{'vgh-blau-color': mandant === 1,
                                        'oesa-gruen-color': mandant === 3,
                                        'oevo-blau-color': mandant === 5}"
                               style="line-break: anywhere; margin-bottom: 10px"
                               @click="downloadAnhang(
                                 vschein.name,
                                 vschein.anzeigename)">
                            <label class="zeiger">
                              <em class="fa-solid fa-paperclip"></em>
                              Versicherungsschein
                            </label>
                          </div>
                        </div>
                        <div v-if="beitragsrechnung">
                        <div :class="{'vgh-blau-color': mandant === 1,
                                        'oesa-gruen-color': mandant === 3,
                                        'oevo-blau-color': mandant === 5}"
                             style="line-break: anywhere"
                             @click="downloadAnhang(
                                 beitragsrechnung.name,
                                 beitragsrechnung.anzeigename)">
                          <label class="zeiger">
                            <em class="fa-solid fa-paperclip"></em>
                            Letzte {{ beitragsrechnung.anzeigename.includes('Beitragsrechnung')
                            ? 'Beitragsrechnung' : 'Lastschriftbenachrichtigung' }}
                          </label>
                        </div>
                      </div>
                        <div v-if="!vschein && !beitragsrechnung">
                          <label>Keine Dokumente vorhanden.</label>
                        </div>
                      </template>
                    </vertragsdetail>
                  </b-collapse>
                </div>
              </div>
              <div class="col-xl-6 info-bereich"
                   id="vertragsdetails">
                <div class="collapsible">
                  <h2 class="ueberschrift">
                    <button class="btn btn-collapse" type="button" :disabled="windowWidth > 1200"
                            v-b-toggle.vertragsdetailscollapse>
                      Vertragsdetails
                      <span class="icon"
                            :class="{'vgh-blau-color': mandant === 1,
                                'oesa-gruen-color': mandant === 3,
                                'oevo-blau-color': mandant === 5}">
                        <em class="fa-solid fa-plus"></em>
                        <em class="fa-solid fa-minus"></em>
                      </span>
                    </button>
                  </h2>
                  <b-collapse id="vertragsdetailscollapse" :visible="windowWidth > 1200">
                    <div v-if="isKFZ(vsnr) && vertrag.spezifischesMerkmal !== null
                      && vertrag.spezifischesMerkmal.length > 0">
                      <vertragsdetail>
                        <template v-slot:unter-ueberschrift>Amtliches Kennzeichen</template>
                        <template v-slot:inhalt>
                          {{ extrahiereKfzKennzeichen(vertrag.spezifischesMerkmal) }}
                        </template>
                      </vertragsdetail>
                      <vertragsdetail>
                        <template v-slot:unter-ueberschrift>Beschreibung</template>
                        <template v-slot:inhalt>
                          {{ extrahiereKfzBeschreibung(vertrag.spezifischesMerkmal) }}
                        </template>
                      </vertragsdetail>
                    </div>
                    <div v-else class="hinweis-kasten mr-xl-3">
                      <div class="copy_bold_16 hinweis-kasten-ueberschrift">
                        <span class="news-badge">i</span>
                        Vertragsdetails in Arbeit
                        <div class="copy_16 hinweis-kasten-text">
                          Aktuell sind noch nicht alle Vertragsdetails verfügbar.
                          Wir arbeiten derzeit daran, diese Funktion für diesen
                          Vertrag zur Verfügung zu stellen.
                        </div>
                      </div>
                    </div>
                  </b-collapse>
                </div>
              </div>
              <div class="info-bereich col-xl-6"
                   v-if="vertrag.zahlart" id="zahlungsinfo">
                <div class="collapsible">
                  <h2 class="ueberschrift">
                    <button class="btn btn-collapse" type="button" :disabled="windowWidth > 1200"
                            v-b-toggle.zahlungsinformationencollapse>
                      Zahlungsinformationen
                      <span class="icon"
                            :class="{'vgh-blau-color': mandant === 1,
                                'oesa-gruen-color': mandant === 3,
                                'oevo-blau-color': mandant === 5}">
                        <em class="fa-solid fa-plus"></em>
                        <em class="fa-solid fa-minus"></em>
                      </span>
                    </button>
                  </h2>
                  <b-collapse id="zahlungsinformationencollapse" :visible="windowWidth > 1200">
                    <vertragsdetail v-if="vertrag.jahresbeitrag">
                      <template v-slot:unter-ueberschrift>Beitrag</template>
                      <template v-slot:inhalt>
                        {{ formatiereGeldbetrag(vertrag.jahresbeitrag) }} / jährlich
                      </template>
                    </vertragsdetail>
                    <vertragsdetail v-if="vertrag.iban">
                      <template v-slot:unter-ueberschrift>IBAN</template>
                      <template v-slot:inhalt>{{ vertrag.iban }}</template>
                    </vertragsdetail>
                    <vertragsdetail v-if="vertrag.kontoinhaber">
                      <template v-slot:unter-ueberschrift>Kontoinhaber</template>
                      <template v-slot:inhalt>{{ vertrag.kontoinhaber }}</template>
                    </vertragsdetail>
                    <vertragsdetail v-if="vertrag.zahlart">
                      <template v-slot:unter-ueberschrift>Zahlungsweise</template>
                      <template v-slot:inhalt>{{ vertrag.zahlart }}</template>
                    </vertragsdetail>
                  </b-collapse>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-4">
            <vertreter-info
              ueberschrift="Sie haben Fragen? Wir helfen Ihnen gerne weiter."
              :anschrift-ausblenden="isSparkasseOderMakler"
              :mail-adresse="vertreterMail"
              :nachricht="!isSparkasseOderMakler"
              :vsnr="vsnr"
              :laufendenr="laufendenr"
              :produktkey="produktkey"
              :termin="!isSparkasseOderMakler"
              :vertrLink="vertreterLink"
              :social-media="!isMakler"
              :vertreter="vertrag.bestandsvertreter"
            >
            </vertreter-info>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import VertreterInfo from '@/components/VertreterInfo.vue';
import PfeilButton from '@/components/PfeilButton.vue';
import Vertragsdetail from '@/components/Vertragsdetail.vue';
import bestaetigungUtil from '@/bestaetigungUtil';
import downloadUtil from '@/downloadUtil';
import api from '@/backend-api';
import { mapState } from 'vuex';
import mandant from '@/mandant';
import Vue from 'vue';
import platform from '@/platform';

export default {
  name: 'Vertragsdetails',
  mixins: [bestaetigungUtil, downloadUtil, mandant, platform],
  data() {
    return {
      vertrag: {},
      vschein: {},
      beitragsrechnung: {},
      vertreterMail: true,
      vertreterLink: false,
      loading: true,
      showHinweis: false,
      googlePlayVghKrankenPath: 'https://play.google.com/store/apps/details?id=net.aokv.gesundheit&hl=de&pli=1',
      googlePlayOesaKrankenPath: 'https://play.google.com/store/apps/details?id=de.ukv.mgm',
      appstoreVghKrankenPath: 'https://apps.apple.com/de/app/vgh-gesundheit/id1141639901',
      appstoreOesaKrankenPath: 'https://apps.apple.com/us/app/ukv-mein-gesundheitsmanager/id6445958679',
    };
  },
  props: {
    vsnr: {
      type: String,
      required: true,
    },
    laufendenr: {
      type: String,
      required: true,
    },
    produktkey: {
      type: String,
      required: true,
    },
    bestaetigung: String,
  },

  components: {
    VertreterInfo,
    PfeilButton,
    Vertragsdetail,
  },
  computed: {
    ...mapState(['vertreter']),
    isSparkasseOderMakler() {
      return (this.vertrag.bestandsvertreter.vertretertyp === 'Sparkasse'
        || this.vertrag.bestandsvertreter.vertretertyp === 'Makler');
    },
    isMakler() {
      return this.vertrag.bestandsvertreter.vertretertyp === 'Makler';
    },
    darfSchadenMelden() {
      const tarifwerkeMitLeistungsfall = ['18', '21', '31', '33', '71'];
      return !tarifwerkeMitLeistungsfall.includes(this.vertrag.vsnr.substr(2, 2));
    },
  },
  methods: {
    formatiereGeldbetrag(beitrag) {
      let geldbetrag = '';
      geldbetrag = Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(beitrag);
      return geldbetrag;
    },
    toggleHinweis() {
      this.showHinweis = !this.showHinweis;
    },
    schliesseHinweis() {
      this.showHinweis = false;
    },
    zurueck() {
      this.$router.push('/vertrag');
    },
    isKrankenversicherung(vsnr) {
      const tarifwerkKranken = ['0428', '2451', '0552', '0551', '1128', '0527', '0228'];
      return tarifwerkKranken.includes(vsnr.substr(0, 4));
    },
    isKFZ(vsnr) {
      const tarifwerkKfz = ['0124', '0324', '0524'];
      return tarifwerkKfz.includes(vsnr.substr(0, 4));
    },
    getAppStoreKranken() {
      if (this.mandant === 1) {
        return this.platform === 'ios' ? this.appstoreVghKrankenPath : this.googlePlayVghKrankenPath;
      }
      if (this.mandant === 3) {
        return this.platform === 'ios' ? this.appstoreOesaKrankenPath : this.googlePlayOesaKrankenPath;
      }
      return this.platform; // Default link for mandant
    },
    setMandantAppName() {
      if (this.mandant === 1) {
        this.mandantAppName = 'VGH Gesundheit';
      }
      if (this.mandant === 3) {
        this.mandantAppName = 'UKV - Mein GesundheitsManager';
      }
      if (this.mandant === 5) {
        this.mandantAppName = 'Ihres Krankenversicherers';
      }
      return this.mandantAppName;
    },
    extrahiereKfzKennzeichen(merkmal) {
      return merkmal.split('/')[0];
    },
    extrahiereKfzBeschreibung(merkmal) {
      let text = merkmal.split('/');
      text = text.slice(1).join(' ');
      text = text.replace(/\(\d+\)/, '');
      return text;
    },
  },

  async created() {
    this.vertrag = await api.getEinzelVertrag(
      this.vsnr,
      this.laufendenr,
      this.produktkey,
      Vue.$keycloak.token,
    )
      .then((response) => response.data)
      .then((vertrag) => vertrag)
      .catch((error) => {
        console.log(error);
      });
    this.loading = false;
    this.vschein = this.vertrag.uploadList
      .find((uploads) => uploads.anzeigename.includes('Versicherungsschein'));
    this.beitragsrechnung = this.vertrag.uploadList
      .find((upload) => upload.anzeigename.includes('Beitragsrechnung') || upload.anzeigename.includes('Lastschriftbenachrichtigung'));
  },
  mounted() {
    this.$emit('view-geladen');
  },
  async beforeMount() {
    this.$root.$refs.app.setZurueckFeld(true);
    await this.$store.dispatch('ladeVertreter');
  },
  updated() {
    if (this.bestaetigung && this.bestaetigung !== 'portalzugang-geloescht') {
      this.$bvToast.show(this.bestaetigung);
    }
  },
};
</script>

<style scoped>
#close-btn {
  position: absolute;
  top: -13px;
  right: 12px;
  z-index: 1;
  padding: 15px;
  margin: -15px;
}

#produkt, #vertragsdaten {
  margin-bottom: 60px;
}

#schaden-melden-btn {
  margin-top: 30px;
  margin-bottom: 20px;
}

.hilfe-icon {
  margin-top: 2px;
  margin-left: 7px;
  cursor: pointer;
}

.hilfe-text {
  width: 515px;
  background-color: #EDF0F3;
  color: #6B7171;
  text-align: left;
  border-radius: 2px;
  padding: 20px 15px;
  box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 22px;
  z-index: 1;
}

.hinweis-text-span {
  padding-inline-end: 6px;
}

.ueberschrift + div {
  margin-top: 30px;
}

.text {
  margin-bottom: 15px;
}

.zeiger {
  cursor: pointer;
}

.btn-collapse {
  padding: 0;
  font-size: inherit;
  line-height: inherit;
  box-shadow: none;
  color: var(--text-color);
  border-radius: 0;
  text-align: left;
  position: relative;
  width: 100%;
}

.btn-collapse:disabled {
  opacity: unset;
}

.btn-collapse .fa-plus, .btn-collapse .fa-minus {
  display: none;
}

.btn-collapse .icon {
  font-size: 15px;
  position: absolute;
  right: 0.5em;
  top: 50%;
  transform: translateY(-50%);
}

@media (max-width: 1200px) {
  .collapsible {
    max-width: 430px;
    border-top: 2px solid #cbcfd3;
  }
  .collapsible .ueberschrift {
    margin: 8px 0;
  }
  .info-bereich:last-child {
    margin-bottom: 40px;
  }
  .info-bereich:last-child .collapsible{
    border-bottom: 2px solid #cbcfd3;
  }
  .btn-collapse.collapsed .fa-plus {
    display: inline-block;
  }
  .btn-collapse.not-collapsed .fa-minus {
    display: inline-block;
  }
  #vertragsdaten {
    margin-bottom: 40px;
  }
  .ueberschrift + div {
    margin: 20px 0;
  }
}

.hinweis-kasten {
    flex-wrap: wrap;
    box-sizing: border-box;
    margin-left: 0;
    margin-bottom: 60px;
    background-color: #EDF0F3;
    text-align: left;
    border-radius: 0;
    border-color: #EDF0F3;
}

.hinweis-kasten-ueberschrift {
  padding: 15px 10px 10px 10px;
}

.hinweis-kasten-text {
  padding: 10px;
}

.news-badge {
  color: white;
  background-color: #484a4f;
  display: inline-block;
  padding: 3px 9px;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  border-radius: 50rem;
  vertical-align: middle;
  margin-right: 5px;
  margin-left: 5px;
}

/* Tablet View */
@media (max-width: 768px) {
  .hilfe-text {
    width: 360px;
  }
}

@media (max-width: 576px) {
  .hilfe-text {
    display: inline-block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 730px;
    width: 80%;
    margin: auto;
    height: fit-content;
  }
  #produkt {
    margin-bottom: 55px;
  }
}
</style>
