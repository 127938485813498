<template>
  <!-- eslint-disable max-len -->
  <div class="servicekarte">
    <div class="container main-content">
      <slot name="benachrichtigung"></slot>
      <template v-if="platform === 'desktop'">
        <h1 class="abstand-unten-20">Servicekarte Ihrer Vertretung</h1>
        <p class="abstand-unten-40 copy_16">Scannen Sie Ihren personalisierten QR-Code mit dem Handy um die Servicekarte Ihrer Vertretung in Ihrer Wallet App zu speichern.</p>
        <qrcode-vue :value="servicekarteUrl" render-as="svg" size="300"></qrcode-vue>
      </template>
    </div>
  </div>
</template>

<script>
import downloadUtil from '@/downloadUtil';
import QrcodeVue from 'qrcode.vue';
import platform from '@/platform';

export default {
  name: 'Servicekarte',
  props: {
    vertreternummer: {
      type: String,
      required: true,
    },
  },
  mixins: [downloadUtil, platform],
  components: {
    QrcodeVue,
  },
  computed: {
    servicekarteUrl() {
      return `${window.location.origin}/#/vertreter/${this.vertreternummer}`;
    },
  },
  mounted() {
    this.$emit('view-geladen');
    if (this.platform !== 'desktop') {
      this.downloadServicekarte(this.vertreternummer);
    }
  },
  async beforeMount() {
    this.$root.$refs.app.setZurueckFeld(true);
  },
};
</script>

<style scoped>

</style>
