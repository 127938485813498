<template>
  <!-- eslint-disable max-len -->
  <div id="app">
    <div v-if="!requiresAuth">
      <router-view>
        <div slot="benachrichtigung">
          <b-toast toast-class="vgh-grau-bg" id="servicekarte-error" toaster="b-toaster-bottom-center" solid auto-hide-delay="10000">
            <template #toast-title><span>Die Datei kann nicht geladen werden. Wir bitten um Verständnis.</span></template>
          </b-toast>
        </div>
      </router-view>
      <div class="container"
           :class="{'toaster-mobile': platform !== 'desktop'}">
        <b-toaster name="b-toaster-bottom-center"></b-toaster>
      </div>
    </div>
    <template v-else>
      <div v-if="!initialesLadenAbgeschlossen || loading" id="ladeanimation">
        <div class="spinner-border"
             :class="{'vgh-blau-color': mandant === 1,
                'oesa-gruen-color': mandant === 3,
                'oevo-blau-color': mandant === 5}" role="status"
             id="lade-spinner"
        >
          <span class="sr-only">Loading...</span>
        </div>
        <div class="ladenachricht"
             :class="{'vgh-blau-color': mandant === 1,
                'oesa-gruen-color': mandant === 3,
                'oevo-blau-color': mandant === 5}">
          <h2>Bitte warten Sie, Ihr Portal wird geladen!</h2>
        </div>
      </div>
      <div>
        <div id="nav" class="container" v-if="!istSeiteUnterIFrame(this.$route.path)">
          <div class="row">
            <div v-if="mandant === 1" id="logo-container-vgh" @click="zurStartseite">
              <div id="logo-vgh" class="col-2 col-md-1" @click="logoMehrValidierung">
                <div id="vgh-logo-container">
                  <img
                    id="vgh-logo"
                    alt="VGH logo"
                    src="https://www.vgh.de/export/sites/vgh/_resources/bilder/header-footer/vgh-AD-logo.svg">
                </div>
              </div>
            </div>
            <div v-else-if="mandant === 3" id="logo-container-oesa" @click="zurStartseite">
              <div id="logo-oesa" class="col-2 col-md-1" @click="logoMehrValidierung">
                  <div id="oesa-logo-container">
                    <img
                      id="oesa-logo"
                      alt="OESA logo"
                      src="@/assets/Meine_OESA_Logo.svg"
                    >
                  </div>
              </div>
            </div>
            <div v-else-if="mandant === 5" id="logo-container-oevo" @click="zurStartseite">
              <div id="logo-oevo" class="col-2 col-md-1" @click="logoMehrValidierung">
                <div id="oevo-logo-container">
                  <img
                    id="oevo-logo"
                    alt="OEVO logo"
                    src="@/assets/OEVO-Logo.png"
                  >
                </div>
              </div>
            </div>
            <div id="nav-items" class="col-10 col-md-9 copy_16" v-if="(user.isAuthenticated && windowWidth > 768 && platform === 'desktop') && !istNectOderZusammenfassung(this.$route.path)">
              <b-nav sticky align="end" v-if="user.isAuthenticated">
                <b-nav-item class="nav-element" to="/vertrag" @click="mehrValidierung('Vertrag')"
                            :disabled="zugriffsStatus === 'KEIN_VERTRAG' || zugriffsStatus === 'GELOESCHT'">Meine Verträge</b-nav-item>
                <b-nav-item class="nav-element" to="/schaden" @click="mehrValidierung('Schaden')"
                            :disabled="zugriffsStatus !== 'eigeneOrga'">Schaden melden</b-nav-item>
                <b-nav-item class="nav-element" to="/postfach" @click="mehrValidierung('Postfach')">Mein Postfach
                  <span v-if="ungeleseneNachrichten >0 " class="news-badge copy_bold_12"
                        :class="{'vgh-blau-bg': mandant === 1,
                              'oesa-gruen-bg': mandant === 3,
                              'oevo-blau-bg': mandant === 5}">
                    {{ungeleseneNachrichten}}</span>
                </b-nav-item>
                <b-nav-item class="nav-element" to="/services" @click="mehrValidierung('Services')"
                            :disabled="istServiceDisabled()">Meine Services</b-nav-item>
                <mehr></mehr>
              </b-nav>
            </div>
          </div>
        </div>
        <div v-if="zurueckFeld" class="card" id="rechteck" @zueruckFeld=setZurueckFeld>
          <div class="container">
            <a class="copy_16 pfeil-btn text-link" @click="zurueck"
               :class="{'oesa-gruen-color-hover': mandant === 3}">
              <em class="fa-solid fa-chevron-left"></em>Zurück
            </a>
          </div>
        </div>
      </div>
      <div v-if="!loading
           && !vertreterAusgewaehlt
           && user.isAuthenticated
           && benutzerStatus === 'aktiv'
           && !this.routeBeimLaden.includes('nect-validierung-mobile')">
        <div v-if="mandant === 5">
          <OevoKeinVertreter />
        </div>
        <div v-else>
          <vertreterauswahl @ausgewaehlt="vertreterGewaehlt">
          </vertreterauswahl>
        </div>
      </div>
      <div v-if="showSparkasseMaklerHinweis && zugriffsStatus === 'sparkasseOderMakler' && benutzerStatus !== 'angelegt'">
        <info-banner @zustimmung="showSparkasseMaklerHinweis=false" typ="sparkasseOderMakler"></info-banner>
      </div>
      <div class="content" v-if="!loading">
        <router-view @view-geladen="onViewGeladen">
          <div slot="benachrichtigung" :set="mandantClass = mandant === 1 ? 'vgh-blau-bg' : mandant === 3 ? 'oesa-gruen-bg' : mandant === 5 ? 'oevo-blau-bg' : ''">
            <b-toast :toast-class="mandantClass" id="name" toaster="b-toaster-bottom-center" solid auto-hide-delay="10000">
              <template #toast-title><span>Ihre persönlichen Daten wurden erfolgreich geändert.</span></template>
            </b-toast>
            <b-toast :toast-class="mandantClass" id="iban" toaster="b-toaster-bottom-center" solid auto-hide-delay="10000">
              <template #toast-title><span>Vielen Dank für die Änderung Ihrer Bankdaten.
                Die Änderung Ihrer Bankdaten kann ggf. einige Tage in Anspruch nehmen.</span></template>
            </b-toast>
            <b-toast :toast-class="mandantClass" id="adresse" toaster="b-toaster-bottom-center" solid auto-hide-delay="10000">
              <template #toast-title><span>Ihre Adresse wurde erfolgreich geändert.</span></template>
            </b-toast>
            <b-toast :toast-class="mandantClass" id="nachricht" toaster="b-toaster-bottom-center" solid auto-hide-delay="10000">
              <template #toast-title><span>Ihre Nachricht wurde erfolgreich versendet.
                <span  v-if="currentRouteName !== 'Postfach' && currentRouteName !== 'Nachrichtenverlauf'">Sie können sie in Ihrem <span
                class="postfach-link" @click="zumPostfach">Postfach</span> sehen. </span></span>
              </template>
            </b-toast>
            <!-- MVGH-1347: bei övo solle keine meldung kommen -->
            <b-toast v-if="mandant !== 5" :toast-class="mandantClass" id="vertreter" toaster="b-toaster-bottom-center" solid auto-hide-delay="10000">
              <template #toast-title><span>Ihre Portalvertretung wurde erfolgreich geändert.</span></template>
            </b-toast>
            <b-toast :toast-class="mandantClass" id="dokument-einreichen" toaster="b-toaster-bottom-center" solid auto-hide-delay="10000">
              <template #toast-title><span>Vielen Dank für das Einreichen Ihres Dokuments!</span></template>
            </b-toast>
            <b-toast :toast-class="mandantClass" id="dokumente-anfordern" toaster="b-toaster-bottom-center" solid auto-hide-delay="10000">
              <template #toast-title><span>Ihre Anfrage wurde erfolgreich an Ihre Vertretung versendet.</span></template>
            </b-toast>
            <b-toast :toast-class="mandantClass" id="termin" toaster="b-toaster-bottom-center" solid auto-hide-delay="10000">
              <template #toast-title><span>Ihr Beratungswunsch wurde an Ihre Vertretung weitergeleitet. <br>
                Es wird sich zeitnah jemand mit Ihnen in Verbindung setzen.</span></template>
            </b-toast>
            <b-toast :toast-class="mandantClass" id="email-adresse" toaster="b-toaster-bottom-center" solid auto-hide-delay="10000">
              <template #toast-title><span>Ihre E-Mail-Adresse wurde erfolgreich geändert.</span></template>
            </b-toast>
            <b-toast :toast-class="mandantClass" id="handynummer" toaster="b-toaster-bottom-center" solid auto-hide-delay="10000">
              <template #toast-title><span>Ihre Handynummer wurde erfolgreich geändert.</span></template>
            </b-toast>
            <b-toast :toast-class="mandantClass" id="passwort" toaster="b-toaster-bottom-center" solid auto-hide-delay="10000">
              <template #toast-title><span>Ihr Passwort wurde erfolgreich geändert.</span></template>
            </b-toast>
            <b-toast :toast-class="mandantClass" id="schadenmeldung" toaster="b-toaster-bottom-center" solid auto-hide-delay="10000">
              <template #toast-title><span>Ihre Schadenmeldung ist eingegangen.
                Wir werden uns zeitnah zur konkreten Schadenaufnahme mit Ihnen in Verbindung setzen.</span></template>
            </b-toast>
            <b-toast :toast-class="mandantClass" id="schadenmeldung-error" toaster="b-toaster-bottom-center" solid auto-hide-delay="10000">
              <template #toast-title><span>Eine Schadenmeldung zu Ihrem Versicherungsvertrag über das Kundenportal
                ist leider noch nicht möglich. Für die Meldung dieses Schadens wenden Sie sich
                bitte direkt an Ihre Vertretung.</span></template>
            </b-toast>
            <b-toast :toast-class="mandantClass" id="servicekarte-error" toaster="b-toaster-bottom-center" solid auto-hide-delay="10000">
              <template #toast-title><span>Die Datei kann nicht geladen werden. Wir bitten um Verständnis.</span></template>
            </b-toast>
            <div v-if="bestaetigung === 'portalzugang-geloescht'">
              <bestaetigung>
                <p>Ihr Kundenportalzugang wurde gelöscht. Sie haben noch 6 Wochen Zugang zum Kundenportal,
                  um Ihre Dokumente im Postfach herunterzuladen. Alternativ können Sie Ihre Dokumente
                  jetzt <span
                    class="postfach-link" @click="zumPostfach">hier</span> herunterladen.
                  Alle weiteren Funktionen stehen Ihnen ab sofort nicht mehr zur Verfügung.
                  Kundenportal reaktivieren?
                  <span class="postfach-link" @click="benutzerReaktivieren">Hier</span> klicken.</p>
              </bestaetigung>
            </div>
            <div v-if="this.vertreterVertraegeAnzahl === 0 && this.$router.currentRoute.name === 'Schaden'">
              <bestaetigung :no-check="true">
                <p>Es sind keine Verträge vorhanden, für die eine Schadenmeldung möglich ist.</p>
              </bestaetigung>
            </div>
            <div v-if="(zugriffsStatus === 'GELOESCHT' || zugriffsStatus === 'KEIN_VERTRAG') && this.$router.currentRoute.name === 'Home' ">
              <bestaetigung :no-check="true">
                <p>
                  Ihr Portalzugang wird zeitnah gelöscht, da Sie die Löschung beantragt haben oder alle Ihre Verträge gekündigt worden sind.
                  Die gewohnten Funktionen stehen Ihnen nun nicht mehr zur Verfügung. Unter <span
                    class="postfach-link" @click="zumPostfach">Mein Postfach</span> können Sie Ihre Dokumente herunterladen.
                  <span v-if="zugriffsStatus === 'GELOESCHT'"><br/>Kundenportal reaktivieren?
                  <span class="postfach-link" @click="benutzerReaktivieren">Hier</span> klicken.</span></p>
              </bestaetigung>
            </div>
          </div>
        </router-view>
      </div>
      <div id="footer" class="footer" v-if="!istSeiteUnterIFrame(this.$route.path)">
        <footer class="text-center text-lg-start text-muted">
          <!-- Left -->
          <div class="container me-5 d-block copy_16">
            <div class="row">
              <section
                class="footer-section d-flex justify-content-center justify-content-lg-between"
              >
                <a :href=$store.state.weiterleitungen.impressum target="_blank" rel=noopener class="me-4 footer-font">
                  Impressum
                </a>
              </section>
              <section
                class="footer-section d-flex justify-content-center justify-content-lg-between"
              >
                <a :href=$store.state.weiterleitungen.datenschutz target="_blank" rel=noopener class="me-4 footer-font">
                  Datenschutz
                </a>
              </section>
              <section
                class="footer-section d-flex justify-content-center justify-content-lg-between"
              >
                <a :href=$store.state.weiterleitungen.nutzungsbedingungen target="_blank" rel=noopener class="me-4 footer-font">
                  Nutzungsbedingungen
                </a>
              </section>
            </div>
          </div>
        </footer>
      </div>
      <div class="container"
        :class="{'toaster-mobile': user.isAuthenticated
          && windowWidth < 1400
          && (vertreterAusgewaehlt || zugriffsStatus === 'KEIN_VERTRAG')
          && !istSeiteUnterIFrame(this.$route.path)
          && !istNectOderZusammenfassung(this.$route.path)
          && platform !== 'desktop'}">
        <b-toaster name="b-toaster-bottom-center"></b-toaster>
      </div>
      <!--Sticky Navigation-->
      <div v-if="user.isAuthenticated
      // Anpassung an das Querformat grosser Tablets
           && windowWidth < 1400
           && (vertreterAusgewaehlt || zugriffsStatus === 'KEIN_VERTRAG')
           && !istSeiteUnterIFrame(this.$route.path)
           && !istNectOderZusammenfassung(this.$route.path)
           && platform !== 'desktop'"
           class="sticky-bar">
        <b-nav v-if="user.isAuthenticated">
          <b-nav-item class="mobil-navigation" to="/vertrag"
                      @click="mehrValidierung('Vertrag')"
                      :disabled="zugriffsStatus === 'KEIN_VERTRAG' || zugriffsStatus === 'GELOESCHT'">
            <em class="fa-solid fa-file-text mobil-icon"></em>
            <br>
            <em class="mobil-icon-text">Meine Verträge</em>
          </b-nav-item>
          <b-nav-item class="mobil-navigation" to="/schaden"
                      @click="mehrValidierung('Schaden')"
                      :disabled="zugriffsStatus !== 'eigeneOrga'">
            <em class="fa-solid fa-exclamation-triangle mobil-icon"></em>
            <br>
            <em class="mobil-icon-text">Schaden melden</em>
          </b-nav-item>
          <b-nav-item class="mobil-navigation" to="/postfach" @click="mehrValidierung('Postfach')">
            <em class="fa-solid fa-envelope mobil-icon"></em>
            <br>
            <em class="mobil-icon-text">Mein Postfach</em>
            <span v-if="ungeleseneNachrichten >0 " class="news-badge mobil-badge copy_bold_12"
                  :class="{'vgh-blau-bg': mandant === 1,
                    'oesa-gruen-bg': mandant === 3,
                    'oevo-blau-bg': mandant === 5}">
              {{ungeleseneNachrichten}}
            </span>
          </b-nav-item>
          <b-nav-item class="mobil-navigation" to="/services"
                      @click="mehrValidierung('Services')"
                      :disabled="zugriffsStatus !== 'eigeneOrga'">
            <em class="fa-solid fa-globe mobil-icon"></em>
            <br>
            <em class="mobil-icon-text">Meine Services</em>
          </b-nav-item>
          <b-nav-item class="mobil-navigation">
            <mehr :mobil="true"
                  class="mobil-icon-text"
                  :class="{'vgh-blau-color': mehrAktiv === true && mandant === 1,
                  'oesa-gruen-color': mehrAktiv === true && mandant === 3,
                  'oevo-blau-color': mehrAktiv === true && mandant === 5}">
            </mehr>
          </b-nav-item>
        </b-nav>
      </div>
    </template>
  </div>
</template>

<script>
import Mehr from '@/side-navigation/Mehr.vue';
import Bestaetigung from '@/components/Bestaetigung.vue';
import Vertreterauswahl from '@/components/Vertreterauswahl.vue';
import InfoBanner from '@/components/InfoBanner.vue';
import { mapState } from 'vuex';
import mandant from '@/mandant';
import zugriffsstatus from '@/zugriffsstatus';
import api from '@/backend-api';
import Vue from 'vue';
import OevoKeinVertreter from '@/components/OevoKeinVertreter.vue';
import platform from '@/platform';

export default {
  name: 'App',
  mixins: [mandant, zugriffsstatus, platform],
  data() {
    return {
      benutzerStatus: '',
      bestaetigung: '',
      logoutAbfrage: false,
      vertreterAusgewaehlt: false,
      partnerStatus: false,
      zurueckFeld: false,
      loading: true,
      vertreterAnzahl: 0,
      vertreterVertraegeAnzahl: 0,
      showSparkasseMaklerHinweis: true,
      initialesLadenAbgeschlossen: false,
      routeBeimLaden: '',
    };
  },

  components: {
    OevoKeinVertreter,
    Mehr,
    Bestaetigung,
    Vertreterauswahl,
    InfoBanner,
  },

  computed: {
    ...mapState([
      'user',
      'fehler',
      'mehrAktiv',
      'zugriffsStatus',
      'postfachStatus',
      'partner',
      'vertreterVertraege',
    ]),
    ungeleseneNachrichten() {
      return this.postfachStatus.ungelesen;
    },
    currentRouteName() {
      return this.$route.name;
    },
    requiresAuth() {
      return this.$route.meta.requiresAuth || Vue.$keycloak.authenticated;
    },
  },

  methods: {
    async logout() {
      this.$store.commit('resetState');
      const logoutOptions = { redirectUri: window.location.origin };
      await this.$keycloak.logout(logoutOptions);
    },
    async vertreterGewaehlt(anzahlVertreter) {
      this.vertreterAusgewaehlt = true;
      await this.$store.dispatch('ladeVertreter');
      await this.$store.dispatch('aktualisiereKundendaten', {
        isSync: false,
      });
      this.vertreterAnzahl = anzahlVertreter;
      if (this.vertreterAnzahl > 1) {
        this.bestaetigung = 'vertreter';
      }

      this.setScrollStatus(true);
    },
    async benutzerReaktivieren() {
      api.sendePortalzugangReaktiviert(
        Vue.$keycloak.token,
      ).then(() => {
        this.$store.dispatch('ladeZugriffsstatus');
        this.$store.dispatch('ladeVertraege');
        this.$store.dispatch('ladeVertraegeGruppiert');
        this.bestaetigung = '';
      });
    },
    logoMehrValidierung() {
      if (this.$store.state.mehrAktiv) {
        this.toggleMehr();
      }
    },
    istSeiteUnterIFrame(path) {
      return (path.includes('nect-start')
        || path.includes('nect-validierung')
        || path.includes('nect-wartungsfenster'));
    },
    istServiceDisabled() {
      return (this.zugriffsStatus !== 'eigeneOrga' || (this.istBenutzerPartnergemeinschaft() && this.mandant === 5));
    },
    istNectOderZusammenfassung(path) {
      return (path === '/nect' || path === '/zusammenfassung' || path === '/nect-wartungsfenster');
    },
    mehrValidierung() {
      if (this.$store.state.mehrAktiv) {
        this.toggleMehr();
      }
    },
    onViewGeladen() {
      this.initialesLadenAbgeschlossen = true;
    },
    setBestaetigung(bestaetigung) {
      this.bestaetigung = bestaetigung;
    },
    setZurueckFeld(wert) {
      this.zurueckFeld = wert;
    },
    setLoading(wert) {
      this.loading = wert;
    },
    toggleMehr() {
      this.$root.$refs.mehr.toggleMehr();
    },
    zurueck() {
      this.$router.back();
    },
    zurStartseite() {
      if (this.$router.currentRoute.name !== 'Home') {
        this.$router.push({ name: 'Home' });
      }
    },
    zumPostfach() {
      this.$router.push({ name: 'Postfach' });
    },
    setScrollStatus(status) {
      if (!status
        && this.vertreterAnzahl > 1
        && Vue.$keycloak.tokenParsed.ivv_benutzerstatus !== 'angelegt'
      ) {
        document.getElementById('app').style.overflow = 'hidden';
      } else {
        document.getElementById('app').style.overflow = 'auto';
      }
    },
  },

  async beforeMount() {
    if (this.$router.currentRoute.meta.requiresAuth === false && !Vue.$keycloak.authenticated) {
      return;
    }
    this.routeBeimLaden = window.location.hash;
    if (Vue.$keycloak.tokenParsed.ivv_benutzerstatus === 'email-adresse-geaendert') {
      this.bestaetigung = 'email-adresse';
      await api.aktualisiereEmailAdresse(Vue.$keycloak.token);
    }
    this.benutzerStatus = Vue.$keycloak.tokenParsed.ivv_benutzerstatus;
    await this.$store.dispatch('ladeWeiterleitungen');

    this.vertreterAusgewaehlt = (await api.hasMapping(Vue.$keycloak.token)).data;
    this.partnerStatus = (await api.getPartner(Vue.$keycloak.token)).status;
    await this.$store.dispatch('ladeVertreterVertraege');
    this.vertreterVertraegeAnzahl = this.$store.state.vertreterVertraege.length;
    await this.$store.dispatch('ladeZugriffsstatus');
    if (this.$store.state.zugriffsStatus !== 'GELOESCHT' && this.$store.state.zugriffsStatus !== 'KEIN_VERTRAG') {
      await this.$store.dispatch('ladeVertraege');
      await this.$store.dispatch('ladeVertraegeGruppiert');
    }
    await this.$store.dispatch('ladePostfachstatus');

    if (this.vertreterAusgewaehlt === true) {
      await this.$store.dispatch('ladeVertreter');
      this.setScrollStatus(true);
    } else if (this.routeBeimLaden.includes('nect-validierung-mobile')) {
      this.setScrollStatus(true);
    } else {
      this.setScrollStatus(false);
    }

    // urlParams löschen, damit die Toasts nicht mehrfach angezeigt werden
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('aktion') && urlParams.get('aktion') === 'passwort-geaendert') {
      this.bestaetigung = 'passwort';
      const newUrl = new URL(window.location.href);
      newUrl.searchParams.delete('aktion');
      window.history.replaceState({}, '', newUrl.toString());
    }
    if (urlParams.has('aktion') && urlParams.get('aktion') === 'handynummer-geaendert') {
      this.bestaetigung = 'handynummer';
      const newUrl = new URL(window.location.href);
      newUrl.searchParams.delete('aktion');
      window.history.replaceState({}, '', newUrl.toString());
    }

    this.loading = false;
    // hack um die falsche weiterleitung von keycloak zu umgehen
    if (window.location.href.includes('#/&iss=')) {
      let currentLocation = window.location.href;
      currentLocation = currentLocation.replace('#/&iss=', '#/?iss=');
      // Simulate the redirect
      window.location.href = currentLocation;
    }
  },
  created() {
    this.$root.$refs.app = this;
  },
  updated() {
    if (this.bestaetigung && this.bestaetigung !== 'portalzugang-geloescht') {
      this.$bvToast.show(this.bestaetigung);
    }
  },
};
</script>

<style>
/* Global View */
#app {
  font-family: 'Sparkasse_web_Rg', sans-serif;
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

#footer {
  background: #484A4F;
}

#logo-vgh {
  text-align: start;
  padding-top: 15px;
}

#logo-container-vgh {
  width: 155px;
  margin-right: 25px;
}

#logo-oevo {
  text-align: start;
  padding-top: 10px;
  margin-bottom: 5px
}

#logo-oesa {
  text-align: start;
}

#logo-container-oevo, #logo-container-oesa {
  width: 180px;
}

#logo-container-vgh, #logo-container-oevo, #logo-container-oesa {
  cursor: pointer;
}

#mehr-icon {
  color: #808485;
  font-size: 25px;
  font-weight: 400;
  line-height: 0.5;
}

#nav {
  font-family: 'Sparkasse_web_Lt', sans-serif;
  font-size: 16px;
  line-height:24px;
}

#nav a {
  color: #2c3e50;
}

#nav-items {
  text-align: end;
}

#vgh-logo {
  width: 100%;
  height: auto;
  padding: 6px 0px;
}

#oevo-logo {
  width: 100%;
  height: auto;
  padding: 15px 0px;
}

#oesa-logo {
  width: 60%;
  height: auto;
  padding-top: 10px;
}

#vgh-logo-container{
  width: 123px;
  height: 60px;
}

#oevo-logo-container{
  width: 175px;
  height: 60px;
}

#oesa-logo-container {
    width: 162px;
    height: 75px;
}

#rechteck {
  background-color: #EDF0F3;
  width: 100%;
  height: 50px;
  border-radius: 0;
  border-color: #EDF0F3;
  border-top: 1px solid #CBCFD3;
  padding-top: 12px;
}

.card {
  width: auto;
  height: auto;
  margin-left: 0;
  background-color: #EDF0F3;
  text-align: left;
  border-radius: 0;
  border-color: #EDF0F3;
  padding: 0;
}

.fa-chevron-left {
  padding-right: 10px;
}

.footer-section {
  padding: 1.5rem;
}

.hilfe-icon {
  display: inline-block;
  color: #FFFFFF;
  border-radius: 0;
  text-align: center;
  height: 20px;
  width: 20px;
  position: absolute;
  font-size: 14px;
}

.mobil-icon {
  color: #808485;
  font-size: 24px;
  font-weight: 300;
  line-height: 0.5;
  padding-bottom: 5px;
  text-align: center;
}

.mobil-icon-text {
  color: black;
  font-family: 'Sparkasse_web_Lt', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

.mobil-navigation {
  width: 20%;
  text-align: center;
  font-size: 24px;
  font-weight: 300;
  line-height: 0.5;
}

.mobil-navigation .disabled .mobil-icon-text {
  color: #808485;
}

.nav-element .disabled {
  color: #808485 !important;
}

.nav-element a{
  padding: 30px 20px 26px 20px;
}

.news-badge {
  color: white;
  background-color: #484a4f;
  display: inline-block;
  padding: 3px 6px;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  border-radius: 50rem;
  vertical-align: middle;
  margin-top: -3px;
  margin-left: 5px;
}

.postfach-link, .hinweis-link {
  color: white;
  text-decoration: underline;
  cursor: pointer;
}

.postfach-link:hover, .hinweis-link:hover {
  color: white;
  text-decoration: none;
}

.sticky-bar {
  background: #EDF0F3;
  bottom: 0;
  height: 70px;
  position: fixed;
  text-align: left;
  width: 100%;
  z-index: 1;
  box-shadow: 0 5px 8px 0 rgba(0,0,0,0.50);
}

.b-toaster-bottom-center {
  max-width: inherit;
  width: inherit;
  left: auto !important;
  right: auto !important;
}

.toaster-mobile > .b-toaster-bottom-center {
  bottom: 70px;
}

.b-toaster-slot {
  margin-left: -15px !important;
  padding-right: 15px !important;
  padding-left: 15px !important;
  bottom: 1rem !important;
}

.b-toaster-slot, .b-toast, .toast {
  border-radius: 0 !important;
  max-width: inherit !important;
}

.toast-header {
  background: none !important;
  border: none !important;
  color: white !important;
}

.toast-header > span {
  width: 100%;
  padding: 5px;
}

.toast-header button.close {
  color: #FFFFFF;
  opacity: 1 !important;
  align-self: start;
  text-shadow: none;
  font-weight: normal;
}

.toast-body {
  display: none !important;
}

.icon {
  color: #484a4f;
}

#ladeanimation {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  z-index: 999;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: white;
}

#lade-spinner {
  width: 5rem;
  height: 5rem;
}

.ladenachricht h2 {
  margin: 2rem;
}

.vgh-blau-color h2 {
  color: #0446be!important;
}

.oesa-gruen-color h2 {
  color: #00935d !important;
}

.oevo-blau-color h2 {
  color: #004494 !important;
}

.main-content {
  text-align: left;
  padding-top: 60px;
  padding-bottom: 1.5rem;
}

.abstand-oben-7 {
  margin-top: 7px;
}

.abstand-oben-30 {
  margin-top: 30px;
}

.abstand-oben-10 {
  margin-top: 10px;
}

.abstand-unten-0 {
  margin-bottom: 0;
}

.abstand-unten-5 {
  margin-bottom: 5px;
}

.abstand-unten-15 {
  margin-bottom: 15px;
}

.abstand-unten-17 {
  margin-bottom: 17px;
}

.abstand-unten-20 {
  margin-bottom: 20px;
}

.abstand-unten-22 {
  margin-bottom: 22px;
}
.abstand-unten-30 {
  margin-bottom: 30px;
}

.abstand-unten-32 {
  margin-bottom: 32px;
}

.abstand-unten-40 {
  margin-bottom: 40px;
}

.abstand-unten-60 {
  margin-bottom: 60px;
}

.footer-font {
  color: #FFFFFF;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;
}

.footer-font:hover {
  color: #FFFFFF;
}

@media (max-width: 1920px) {
  #logo-container-vgh {
    margin-right: 0;
  }

  #logo-container-oevo, #logo-container-oesa {
    width: 183px;
  }
  #oevo-logo, #oesa-logo {
    padding: 15px 0;
  }
  #oevo-logo-container {
    width: 180px;
  }
  #oesa-logo-container {
    width: 120px;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  #logo-container-vgh {
    width: 140px;
  }

  #logo-container-oevo, #logo-container-oesa {
    width: 127px;
  }

  .nav-element a {
    padding: 4.8px;
    padding-top: 29px;
  }
  #oevo-logo-container {
    width: 120px;
    margin-top: 8.5px
  }
  #oesa-logo-container {
    width: 120px;
  }
}

/* Tablet View */
@media (max-width: 768px) {
  #footer {
    margin-bottom: 70px;
  }
  #oevo-logo, #oesa-logo {
    padding: 10px 0;
  }
  #oevo-logo-container {
    width: 180px;
  }
  #oesa-logo-container {
    width: 100px;
  }
  .mobil-badge {
    position: absolute;
    top: 7px;
    right: calc(51% - 8mm);
  }
  .mobil-abstand {
    margin-bottom: 69px;
  }
  .footer-font {
    color: #FFFFFF;
    font-size: small;
  }
  .footer-section {
    padding: 1rem;
  }
}

/* Mobile View */
@media (max-width: 576px) {
  .main-content {
    padding-top: 30px;
    padding-bottom: 0;
  }
}

@media (hover: none), (pointer: coarse) {
  #footer {
    margin-bottom: 70px;
  }
}

/* iOS */
@supports (-webkit-touch-callout: none) {
  @media (min-width: 769px) and (max-width: 992px) {
    #nav-items {
      margin-left: 60px;
    }
  }
}
</style>
