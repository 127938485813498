<template>
  <div id="cyberschaden">
    <p>
      Sie sind Opfer eines Cyber-Angriffs geworden und benötigen schnelle
      Unterstützung im Schadenfall? Unter unserer Cyber-Schadenhotline
      können Sie uns rund um die Uhr erreichen und erhalten gezielte Hilfe
      von unseren Cyber-Experten.
    </p>
    <p><b>
      Cyber-Schadenhotline: <a href="tel:08008440701" class="text-link">0800 844-0701</a>
    </b></p>
    <p>
      Von der ersten Analyse des Vorfalls über die Wiederherstellung von
      Daten bis hin zur finanziellen Absicherung bei Cyber-Schäden – wir sind für Sie da.
      Zögern Sie nicht, uns im Schadenfall sofort zu kontaktieren. Insbesondere bei einem
      Cyber-Vorfall ist eine schnelle Reaktion entscheidend, um größere Schäden zu verhindern.
      <span v-if="vsnr.substring(0, 4) === '0122'">
        Unsere erfahrenen IT-Forensiker untersuchen den Vorfall umfassend und unterstützen
        Sie bei der Schadenbehebung.
      </span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'CyberSchaden',
  props: {
    vsnr: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
  #cyberschaden {
    padding-top: 5px;
    color: var(--text-color);
  }

  .text-link {
    color: var(--text-color);
  }

  .text-link:hover {
    border-bottom: none;
    color: var(--text-color);
  }

  @media (min-width: 1199px) {
    #cyberschaden {
      margin-right: 1em;
    }
  }
</style>
