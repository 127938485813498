<template>
  <!-- eslint-disable max-len -->
  <div class="vertreter" >
    <h2 id="ueberschrift">{{ ueberschrift }}</h2>
    <div class="row" v-if="vertreterbild">
      <b-img :src="vertreterbild" class="abstand-unten-20 col-12 bild"
             @error="$event.target.src = getDefaultMandantenBild()">
      </b-img>
    </div>
    <h3 class="abstand-unten-20">{{vertreter.name}}</h3>
    <p v-if="vertreter.vertretertyp === 'Makler'" class="copy_12">
      Dieser Vertrag wird von Ihrem Versicherungsmakler betreut.
      <br>
      Bei Fragen wenden Sie sich bitte an Ihren Versicherungsmakler oder
      an unser Kundendienstcenter unter folgender Kontaktmöglichkeit.
    </p>
    <p v-if="!anschriftAusblenden && vertreter.anschrift!=null" id="anschrift" class="copy_16 abstand-unten-30">
      {{vertreter.anschrift.strasse + ' ' + vertreter.anschrift.hausnummer}}
      <br>
      {{vertreter.anschrift.postleitzahl + ' ' + vertreter.anschrift.ort}}
    </p>
    <p v-if="vertreter.telefonnummer" id="telefon" class="abstand-unten-20 copy_16">
      <em id="telefon-icon" class="fa-solid fa-phone"
         :class="{'vgh-blau-color': mandant === 1,
         'oesa-gruen-color': mandant === 3,
         'oevo-blau-color': mandant === 5}"
      ></em>
      <strong id="telefon-nummer">{{ vertreter.telefonnummer }}</strong>
    </p>
    <p v-if="vertreter.email" class="abstand-unten-20 copy_16">
      <em id="brief-icon" class="fa-solid fa-envelope"
         :class="{'vgh-blau-color': mandant === 1,
         'oesa-gruen-color': mandant === 3,
         'oevo-blau-color': mandant === 5}"
      ></em>
      <strong id="e-mail">{{vertreter.email}}</strong>
    </p>
    <p v-if="webseite" class="abstand-unten-20">
      <em id="web-icon" class="fa-solid fa-globe"
         :class="{'vgh-blau-color': mandant === 1,
          'oesa-gruen-color': mandant === 3,
          'oevo-blau-color': mandant === 5}"></em>
      <a id="website" class="text-link" :href="webseite" target="_blank"
              :class="{'vgh-blau-color': mandant === 1,
                'vgh-blau-text-decoration-hover': mandant === 1,
                'oesa-gruen-color': mandant === 3,
                'oesa-gruen-text-decoration-hover': mandant === 3,
                'oevo-blau-color': mandant === 5,
                'oevo-blau-text-decoration-hover': mandant === 5}">Webseite
      </a>
    </p>
    <div class="abstand-unten-20" @click="zurNachricht">
      <pfeil-button
        v-if="nachricht && zugriffsStatus === 'eigeneOrga'"
        class= "copy_16"
        :class="{blau: true}"
        :button-text="nachrichtText"
      >
      </pfeil-button>
    </div>
    <div class="abstand-unten-20" @click="zumBeratungstermin">
      <pfeil-button
        v-if="termin && zugriffsStatus === 'eigeneOrga'"
        class= "copy_16"
        :class="{blau: true}"
        :button-text="terminText">
      </pfeil-button>
    </div>
    <div class="abstand-unten-20">
      <pfeil-button
        v-if="vertrLink && (zugriffsStatus === 'eigeneOrga')"
        class= "copy_16"
        :class="{blau: true}"
        :button-text="vertreterText"
        :path="vertreterPath">
      </pfeil-button>
    </div>
    <template v-if="vertreterBildVorhanden && vertreter.anschrift">
      <div class="abstand-unten-15">
        <pfeil-button
          class="copy_16 blau"
          :button-text="servicekarteText"
          @click="zurServicekarte">
        </pfeil-button>
      </div>
      <div class="abstand-unten-20 row justify-content-xl-between mx-0">
        <img class="btn col-auto p-0 btn-servicekarte" src="@/assets/add_to_apple_wallet_badge.svg" @click="zurServicekarte" alt="Servicekarte hinzufügen"/>
        <img class="btn col-auto p-0 btn-servicekarte ml-3 ml-xl-0" src="@/assets/add_to_google_wallet_badge.svg" @click="zurServicekarte" alt="Servicekarte hinzufügen"/>
      </div>
    </template>
  </div>
</template>

<script>
import mandant from '@/mandant';
import { mapState } from 'vuex';
import vertreterbildUtil from '@/vertreterbildUtil';
import platform from '@/platform';
import downloadUtil from '@/downloadUtil';
import PfeilButton from './PfeilButton.vue';

export default {
  name: 'VertreterInfo',
  mixins: [mandant, vertreterbildUtil, platform, downloadUtil],
  data() {
    return {
      bild: '',
      nachrichtText: 'Nachricht schreiben',
      terminText: 'Termin vereinbaren',
      terminPath: '',
      vertreterText: 'Vertretung ansehen',
      vertreterPath: '/vertreter',
      servicekarteText: 'Servicekarte Ihrer Vertretung',
      servicekartePath: 'vertreter',
    };
  },
  components: { PfeilButton },
  props: [
    'anschrift-ausblenden',
    'mail-adresse',
    'nachricht',
    'social-media',
    'termin',
    'ueberschrift',
    'vertrLink',
    'vertreter',
    'vsnr',
    'laufendenr',
    'produktkey',
    'konversationsId',
    'nachrichtenverlauf',
  ],
  computed: {
    ...mapState(['zugriffsStatus']),
    webseite() {
      if (this.vertreter.vertretertyp === 'Makler') {
        return false;
      }
      if (this.vertreter.urlShortcut) {
        if (this.vertreter.vertretertyp === 'Sparkasse' || this.vertreter.urlShortcut.startsWith('http')) {
          return this.vertreter.urlShortcut;
        }
        let endpoint = this.vertreter.urlShortcut;
        if (endpoint === null || this.vertreter.vertretertyp === 'Verbundabteilung' || this.vertreter.vertretertyp === 'sonstige') {
          endpoint = '';
        }
        return this.$store.state.weiterleitungen.mandantBasisUrl + endpoint;
      }
      return '';
    },
    vertreterbild() {
      if (this.vertreter.vertretertyp === 'Sparkasse') {
        const images = require.context('../assets/', false, /\.jpg$/);
        return images('./Sparkasse_pic.jpg');
      }
      if (this.vertreter.stageImage) {
        return this.vertreter.stageImage;
      }
      return this.getDefaultMandantenBild();
    },
  },
  methods: {
    zurNachricht() {
      this.$router.push({
        name: 'Nachricht',
        params: {
          vsnr: this.vsnr,
          laufendenr: this.laufendenr,
          produktkey: this.produktkey,
          konversationsId: this.konversationsId,
          nachrichtenverlauf: this.nachrichtenverlauf,
        },
      });
    },
    zumBeratungstermin() {
      this.$router.push({
        name: 'Beratungstermin',
        params: {
          from: this.$router.currentRoute.name,
          vsnr: this.vsnr,
          laufendenr: this.laufendenr,
          produktkey: this.produktkey,
          konversationsId: this.konversationsId,
        },
      });
    },
    zurServicekarte() {
      if (this.platform === 'desktop') {
        this.$router.push({
          name: 'Servicekarte',
          params: {
            vertreternummer: this.vertreter.vertreternummer,
          },
        });
      } else {
        this.downloadServicekarte(this.vertreter.vertreternummer);
      }
    },
  },
};
</script>

<style scoped>
#brief-icon {
  font-size: 24px;
  margin-right: 9px;
  vertical-align: top;
  color: #484a4f;
}

#web-icon {
  color: #484a4f;
  font-size: 24px;
  margin-right: 9px;
  vertical-align: top;
}

#telefon-icon {
  font-size: 21px;
  margin-right: 12px;
  color: #484a4f;
}

#website {
  font-weight: 400;
  font-family: Sparkasse_web_Lt,sans-serif;
  color: #484a4f;
  cursor: pointer;
}

#telefon-nummer, #e-mail {
  font-weight: 400;
  font-family: Sparkasse_web_Lt,sans-serif;
}

#website:hover {
  text-decoration: underline;
  text-decoration-color: #484a4f;
}

#ueberschrift {
  margin-bottom: 30px;
}

.vertreter {
  text-align: left;
}

.btn-servicekarte {
  height: 3.2em;
}

@media (max-width: 576px) {
  #ueberschrift {
    margin-bottom: 20px;
  }

  .btn-servicekarte {
    height: 2.5em;
  }
}

</style>
