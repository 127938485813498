<template>
  <div id="rechtskompass">
    <p>
      Sie haben schlaflose Nächte und benötigen eine Lösung für Ihr rechtliches Problem?
      Mit unserem VGH Rechts-Kompass können Sie uns mitteilen, worum es geht und erhalten
      sofort maßgeschneiderte Empfehlungen für die passenden Rechtsservices.
    </p>
    <p>
      Der VGH Rechts-Kompass nimmt Sie 24 Stunden am Tag an die Hand und bietet Ihnen Lösungen,
      die genau auf Ihre Bedürfnisse zugeschnitten sind. Von der telefonischen Rechtsberatung über
      Dokumentenvorlagen bis hin zur gerichtlichen Vertretung durch einen erfahrenen Anwalt – wir
      haben alles, was Sie brauchen, um rechtliche Probleme schnell und effektiv zu lösen.
    </p>
    <p>
      Hier können Sie Ihre Schadenmeldungen zur Rechtsschutzversicherung über unseren
      VGH Rechts-Kompass starten:
    </p>
    <div class="button-group">
      <a href="https://vgh-rechtskompass.dahag-apps.de/" target="_blank" class="button-ivv button-vgh white">VGH Rechts-Kompass</a>
      <input type="button" class="button-ivv" value="Zurück" @click="$root.$refs.app.zurueck()">
    </div>
    <p>
      Hinweis: Mit einem aktuellen Rechtsschutzvertrag (ab Oktober 2024) können Sie Ihren
      Meldebonus nutzen und 150 EUR sparen. Sollten Sie den aktuellen Tarif noch nicht haben,
      kontaktieren Sie gerne Ihre {{ mandantVertretung[mandant] }}. Diese berät Sie gerne.
    </p>
  </div>
</template>

<script>
import mandant from '@/mandant';

export default {
  name: 'RechtsKompass',
  mixins: [mandant],
  data() {
    return {
      mandantVertretung: {
        1: 'VGH-Vertretung',
        5: 'Vertretung der Öffentlichen',
      },
    };
  },
};

</script>

<style scoped>
  #rechtskompass {
    padding-top: 5px;
    color: var(--text-color);
  }

  a.button-ivv {
    margin-right: 15px;
  }

  .button-group {
    margin: 2em 0;
  }

  @media (min-width: 1199px) {
    #rechtskompass {
      margin-right: 1em;
    }
  }
</style>
