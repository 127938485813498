<template>
  <div>
    <div class="vertreter-karte flex-row-reverse">
      <div class="vertreter-bild-container"
           :class="{'vertreter-bild-container-vgh': mandant === 1 || mandant === 5}">
        <img class="vertreter-bild"
             :class="{'vertreter-bild-vgh': mandant === 1 && !mandantenBild,
                      'vertreter-bild-oevo': mandant === 5 && !mandantenBild,
                      'mandanten-bild': mandantenBild}"
             :src="vertreterbild()" alt=""
             @error="$event.target.src = getMandantenBild()">
      </div>
      <div class="body">
        <span>
          <input v-model="selectedVertreter"
                 :id="index"
                 type="radio"
                 :class="{'input-vgh': mandant === 1,
                    'input-oesa': mandant === 3,
                    'input-oevo': mandant === 5}"
                 :name="vertreter.name"
                 :value="vertreter">
          <label :for="index" class="copy_18" @click="clickVertreter">
            <p class="vertreter-name">{{ vertreter.name }}</p>
          </label>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import mandant from '@/mandant';
import vertreterbildUtil from '@/vertreterbildUtil';

export default {
  name: 'Vertreterauswahlkarte',
  props: [
    'index',
    'vertreter',
    'selectedVertreter',
  ],
  mixins: [mandant, vertreterbildUtil],
  data() {
    return {
      mandantenBild: false,
    };
  },
  methods: {
    vertreterbild() {
      if (this.vertreter.stageImage) {
        return this.vertreter.stageImage;
      }
      this.mandantenBild = true;
      return this.getQuadratischesMandantenBild();
    },
    clickVertreter() {
      this.$emit('selectVertreter', this.vertreter);
    },
    getMandantenBild() {
      this.mandantenBild = true;
      return this.getQuadratischesMandantenBild();
    },
  },
};
</script>

<style scoped>
input {
  display: none;
  margin: 0;
}

label {
  margin: 0 0 0 40px;
}

input[type="checkbox"]:checked + label::before,
input[type="checkbox"]:not(:checked) + label::before,
input[type="radio"]:checked + label::before,
input[type="radio"]:not(:checked) + label::before {
  position: absolute;
  top: 58px;
  left: 15px;
}

.vertreter-bild.mandanten-bild {
  width: 100%;
}

.vertreter-bild {
  height: 140px;
  width: 100px;
  object-fit: cover;
}

.vertreter-bild-vgh {
  width: 120px;
  object-position: top 0 right -35px;
}

.vertreter-bild-oevo {
  width: 120px;
  object-position: top 0 right -88px;
}

.vertreter-bild-container {
  width: 100px;
  height: 140px;
}

.vertreter-bild-container-vgh {
  width: 120px;
}

.vertreter-karte {
  height: 140px;
  width: 330px;
  background-color: #EDF0F3;
  margin-bottom: 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
}

.vertreter-karte .body {
  display: flex;
  flex: 1 1 auto;
  min-height: 1px;
  align-items: center;
}

.vertreter-name{
  margin: 0;
  padding: 0;
  max-width: 160px;
  float: right;
}

@media (max-width: 769px) {
  .vertreter-karte {
    width: auto;
    margin-bottom: 20px;
  }
}
</style>
